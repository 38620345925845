import "./weather.css"
export const WeatherSun = () => {
    return (
    <svg width="100%" height="100%" viewBox="0 0 93 62" fill="none" xmlns="http://www.w3.org/2000/svg">

        <g id="sunGroup" class="sunGroup">
        <path d="M61.1918 14.5273C52.0242 14.5273 44.5693 21.9267 44.5693 31.0205C44.5693 40.1143 52.0242 47.4365 61.1918 47.4365C70.3595 47.4365 77.8143 40.0732 77.8143 31.0205C77.8143 21.9677 70.3595 14.5273 61.1918 14.5273ZM61.1918 46.0861C52.7774 46.0861 45.9313 39.3286 45.9313 31.0205C45.9313 22.7123 52.7774 15.8778 61.1918 15.8778C69.6063 15.8778 76.4523 22.6712 76.4523 31.0205C76.4523 39.3697 69.6063 46.0861 61.1918 46.0861Z" fill="white"/>
        <path d="M76.4702 31.021C76.4702 39.3331 69.6157 46.0938 61.1909 46.0938C52.7661 46.0938 45.9116 39.3331 45.9116 31.021C45.9116 22.7088 52.7661 15.8711 61.1909 15.8711C69.6157 15.8711 76.4702 22.6677 76.4702 31.021Z" fill="#0F0F0F"/>
        <path d="M61.0242 11.165C60.6528 11.165 60.3525 10.8576 60.3525 10.4772V0.769832C60.3525 0.389457 60.6528 0.0820312 61.0242 0.0820312C61.3957 0.0820312 61.6959 0.389457 61.6959 0.769832V10.4772C61.6959 10.8576 61.3957 11.165 61.0242 11.165Z" fill="white"/>
        <path d="M71.4656 13.85C71.3486 13.85 71.2262 13.819 71.1144 13.7571C70.7792 13.5713 70.6621 13.1533 70.859 12.8281L75.8346 4.45196C76.0262 4.12682 76.4572 4.01328 76.7925 4.20423C77.1277 4.39003 77.2448 4.80806 77.0479 5.1332L72.0723 13.5094C71.9446 13.7261 71.7051 13.85 71.4656 13.85Z" fill="white"/>
        <path d="M79.1678 21.5758C78.929 21.5758 78.7006 21.4478 78.576 21.2237C78.3891 20.8876 78.4981 20.4555 78.8252 20.2635L87.198 15.2913C87.5251 15.094 87.9455 15.2113 88.1324 15.5474C88.3193 15.8835 88.2103 16.3156 87.8832 16.5077L79.5104 21.4798C79.4014 21.5438 79.2871 21.5758 79.1678 21.5758Z" fill="white"/>
        <path d="M91.5704 31.6442H81.8592C81.4799 31.6442 81.1733 31.3439 81.1733 30.9725C81.1733 30.601 81.4799 30.3008 81.8592 30.3008H91.5704C91.9497 30.3008 92.2563 30.601 92.2563 30.9725C92.2563 31.3439 91.9497 31.6442 91.5704 31.6442Z" fill="white"/>
        <path d="M87.5387 46.7615C87.4245 46.7615 87.3052 46.7312 87.1962 46.6705L78.8273 41.9601C78.5004 41.7731 78.3863 41.3688 78.5783 41.0504C78.765 40.732 79.1853 40.6208 79.5122 40.8078L87.8811 45.5182C88.208 45.7052 88.3221 46.1095 88.1301 46.4279C88.0056 46.6402 87.7721 46.7615 87.5387 46.7615Z" fill="white"/>
        <path d="M76.4431 57.8402C76.1981 57.8402 75.9638 57.7164 75.836 57.4995L70.8567 49.1214C70.665 48.7962 70.7768 48.378 71.1123 48.1922C71.4478 48.0064 71.8792 48.1148 72.0709 48.44L77.0502 56.8181C77.2419 57.1434 77.1301 57.5615 76.7946 57.7473C76.6827 57.8093 76.5656 57.8402 76.4431 57.8402Z" fill="white"/>
        <path d="M61.0242 61.8692C60.6527 61.8692 60.3525 61.5711 60.3525 61.2023V51.7899C60.3525 51.4211 60.6527 51.123 61.0242 51.123C61.3956 51.123 61.6958 51.4211 61.6958 51.7899V61.2023C61.6958 61.5711 61.3956 61.8692 61.0242 61.8692Z" fill="white"/>
        <path d="M45.9435 57.8402C45.8264 57.8402 45.7041 57.8093 45.5923 57.7473C45.2572 57.5616 45.1401 57.1435 45.337 56.8184L50.3113 48.4422C50.5029 48.1171 50.9338 48.0035 51.269 48.1945C51.6041 48.3803 51.7212 48.7983 51.5297 49.1234L46.5553 57.4996C46.4276 57.7164 46.1882 57.8402 45.9488 57.8402H45.9435Z" fill="white"/>
        <path d="M34.8431 46.7615C34.6043 46.7615 34.3759 46.6402 34.2513 46.4279C34.0644 46.1095 34.1734 45.7001 34.5005 45.5182L42.8733 40.8078C43.2004 40.6208 43.6208 40.732 43.8077 41.0504C43.9946 41.3688 43.8856 41.7782 43.5585 41.9601L35.1857 46.6705C35.0766 46.7312 34.9624 46.7615 34.8431 46.7615Z" fill="white"/>
        <path d="M40.5216 31.6442H30.8201C30.4381 31.6442 30.1294 31.3439 30.1294 30.9725C30.1294 30.601 30.4381 30.3008 30.8201 30.3008H40.5216C40.9036 30.3008 41.2124 30.601 41.2124 30.9725C41.2124 31.3439 40.9036 31.6442 40.5216 31.6442Z" fill="white"/>
        <path d="M43.214 21.5758C43.0998 21.5758 42.9805 21.5438 42.8715 21.4798L34.5026 16.5077C34.1757 16.3103 34.0616 15.8835 34.2536 15.5474C34.4403 15.2113 34.8606 15.094 35.1875 15.2913L43.5564 20.2635C43.8833 20.4555 43.9974 20.8876 43.8054 21.2237C43.6809 21.4478 43.4474 21.5758 43.214 21.5758Z" fill="white"/>
        <path d="M50.9213 13.85C50.6763 13.85 50.442 13.7261 50.3142 13.5094L45.3347 5.1332C45.143 4.80806 45.2548 4.39003 45.5903 4.20423C45.9259 4.01328 46.3572 4.12682 46.549 4.45196L51.5284 12.8281C51.7201 13.1533 51.6083 13.5713 51.2675 13.7571C51.1556 13.819 51.0385 13.85 50.916 13.85H50.9213Z" fill="white"/>

        </g>

        <path d="M40.7868 44.7818C40.3935 44.7818 39.9952 44.8379 39.6589 44.9499C39.7158 44.5068 39.7727 44.1197 39.7727 43.6766C39.7727 40.3559 37.0668 37.6973 33.7348 37.6973C30.8012 37.6973 28.3747 39.6887 27.7538 42.4033C27.076 41.8482 26.2327 41.5732 25.2704 41.5732C23.2939 41.5732 21.6021 42.9585 21.2658 44.7818H20.8157C18.5599 44.7818 16.6973 46.6103 16.6973 48.8207C16.6973 51.0311 18.5599 52.8086 20.8157 52.8086H40.7868C43.0426 52.8086 44.9051 51.0362 44.9051 48.8207C44.9051 46.6052 43.0426 44.7818 40.7868 44.7818Z" fill="#0F0F0F"/>
        <path d="M40.5561 53.48H20.709C18.1279 53.48 16.0249 51.4058 16.0249 48.8581C16.0249 46.3104 18.1176 44.1899 20.6936 44.1847C21.2695 42.3061 23.0948 40.9422 25.136 40.9422C25.9124 40.9422 26.6323 41.112 27.2596 41.4363C28.2005 38.8011 30.6788 37.0254 33.5479 37.0254C37.1831 37.0254 40.1396 40.0003 40.1396 43.6597C40.1396 43.8502 40.1293 44.0303 40.1139 44.2053C40.2579 44.1899 40.407 44.1847 40.5561 44.1847C43.1372 44.1847 45.2402 46.2846 45.2402 48.8581C45.2402 51.4315 43.1372 53.48 40.5561 53.48ZM20.709 45.3685C18.7809 45.3685 17.2075 46.9331 17.2075 48.8581C17.2075 50.783 18.7757 52.2962 20.709 52.2962H40.5561C42.4842 52.2962 44.0576 50.7573 44.0576 48.8581C44.0576 46.9589 42.4894 45.3685 40.5561 45.3685C40.2219 45.3685 39.8877 45.4148 39.6203 45.5075C39.4249 45.5692 39.2141 45.5332 39.0598 45.4045C38.9005 45.2759 38.8233 45.0751 38.849 44.8744L38.8645 44.7406C38.9107 44.3597 38.957 44.0252 38.957 43.6597C38.957 40.6539 36.5301 38.2092 33.5479 38.2092C30.9565 38.2092 28.7507 39.9746 28.1799 42.5017C28.1337 42.7024 27.9897 42.8671 27.7943 42.934C27.5989 43.0009 27.383 42.9598 27.2236 42.8311C26.6631 42.3627 25.9587 42.1311 25.136 42.1311C23.4856 42.1311 22.0253 43.3149 21.7374 44.8898C21.6859 45.1729 21.4391 45.3736 21.1563 45.3736H20.709V45.3685Z" fill="white"/>
        <path d="M6.14272 51.5845C6.62955 51.5845 7.07494 51.6307 7.52034 51.7589C7.4323 51.2768 7.34425 50.7486 7.34425 50.2204C7.34425 46.2716 10.5915 43.0664 14.5949 43.0664C18.0648 43.0664 20.9547 45.4818 21.6643 48.687C22.467 48.0716 23.4873 47.7229 24.6422 47.7229C26.9987 47.7229 28.9563 49.3896 29.4017 51.5845H29.9352C32.649 51.5845 34.8294 53.7333 34.8294 56.4153C34.8294 59.0974 32.649 61.2 29.9352 61.2H6.14272C3.42891 61.2 1.24854 59.0513 1.24854 56.4153C1.24854 53.7794 3.42891 51.5845 6.14272 51.5845Z" fill="#0F0F0F"/>
        <path d="M30.0411 61.8662H6.37433C3.36576 61.8662 0.913574 59.4355 0.913574 56.4462C0.913574 53.4569 3.36061 50.9797 6.37433 50.9797C6.60616 50.9797 6.82253 50.99 7.0286 51.0107C6.99769 50.7469 6.97708 50.478 6.97708 50.2039C6.97708 45.8958 10.4751 42.3945 14.7818 42.3945C18.1304 42.3945 21.1184 44.5874 22.1693 47.7215C22.9472 47.3077 23.8333 47.0905 24.7761 47.0905C27.1819 47.0905 29.2941 48.7041 29.9741 50.9848H30.0411C33.0496 50.9848 35.5018 53.4363 35.5018 56.4514C35.5018 59.4665 33.0548 61.8714 30.0411 61.8714V61.8662ZM6.37433 52.1692C4.02003 52.1692 2.09846 54.0879 2.09846 56.4462C2.09846 58.8046 4.01487 60.6767 6.37433 60.6767H30.0411C32.3954 60.6767 34.3169 58.7787 34.3169 56.4462C34.3169 54.1138 32.4005 52.1692 30.0411 52.1692H29.5104C29.2271 52.1692 28.985 51.9675 28.9283 51.6934C28.5316 49.7126 26.7852 48.2748 24.7761 48.2748C23.7869 48.2748 22.8906 48.5696 22.1745 49.123C22.0148 49.2471 21.7984 49.2833 21.6078 49.2109C21.4172 49.1437 21.2729 48.9782 21.2317 48.7817C20.5723 45.7665 17.8574 43.584 14.7767 43.584C11.1293 43.584 8.15681 46.5526 8.15681 50.2039C8.15681 50.6849 8.23924 51.1814 8.32166 51.6468C8.35772 51.8485 8.2856 52.0606 8.1362 52.195C7.98165 52.3347 7.77043 52.3812 7.56952 52.3243C7.21921 52.2209 6.84829 52.1743 6.36918 52.1743L6.37433 52.1692Z" fill="white"/>
    </svg>

    )
}