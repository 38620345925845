import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './Pages/Home';
import { Branding } from './Pages/services/Branding';
import { Packaging } from './Pages/services/Packaging';
import { Presentation } from './Pages/services/Presentation';
import { Illustration } from './Pages/services/Illustration';
import { Visualmedia } from './Pages/services/Visualmedia';
import { Web } from './Pages/services/Web';
import { Threed } from './Pages/services/Threed';
import { Aboutus } from './Pages/Aboutus';
import { Reachout } from './Pages/Reachout';
import { Faq } from './Pages/faq';

export const Router = () => {
return (
  <BrowserRouter >
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/branding" element={<Branding />} />
      <Route path="/packaging" element={<Packaging />} />
      <Route path="/presentations" element={<Presentation />} />
      <Route path="/visual-media" element={<Visualmedia />} />
      <Route path="/web" element={<Web />} />
      <Route path="/illustration" element={<Illustration />} />
      <Route path="/threed" element={<Threed />} />
      <Route path="/aboutus" element={<Aboutus />} />
      <Route path="/reachout" element={<Reachout />} />
      <Route path='/faq' element={<Faq />} />
    </Routes>
  </BrowserRouter>
)
}