import { Container } from "@mui/material"
import { Footer } from "../Components/Footer"
import { Header } from "../Components/Header"
import { useRef } from "react"
import BackgroundVideo from 'react-background-video-player';
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { SplitText } from 'gsap/all';

import bgVideo from '../assets/bg.mp4';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, FreeMode } from 'swiper/modules';
import 'swiper/css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import './aboutus.css';
import { reviewlist } from "../confdata";


gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollSmoother, SplitText);
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1
  }
};

export const Aboutus = () => {
  const main = useRef();
  const videoRef = useRef();

  useGSAP(() => {
    gsap.to(".aboutus-title-span", {opacity: 0});
    var split = new SplitText(".aboutus-title-span", {type: "words, chars",
        wordDelimiter: "*",
        wordsClass: "word"
    });
    
    gsap.fromTo(".aboutus-detail", { autoAlpha: 1, x: -1200 },
      { autoAlpha: 1, x: 0, duration: 0.5 }, 
    )
    
    gsap.fromTo(".aboutus-title", { autoAlpha: 1, x: -1200,
      onStart: () => {
      //  gsap.to(".aboutus-title-span", {opacity: 0});
      }
     },
      { autoAlpha: 1, x: 0, duration: 0.5,
        onComplete: () => {
          gsap.to(".aboutus-title-span", {opacity: 1});
          // gsap.set(split, {perspective:800});
          gsap.from(split.chars, {
            duration: 0.5, 
            autoAlpha: 1,
            y: 300, 
            
            stagger: 0.05,
            delay: 0.5,
          });
        }
       })
    
    var detailLines = new SplitText(".aboutus-detail", {
      type: "lines"
    })
    gsap.from(detailLines.lines, {
      opacity: 0,
      duration: 1, 
      x: -50, 
      y: 10,
      stagger: 0.1,
      delay: 2
    })

    var itallyText = new SplitText(".aboutus-bottom", {
      type: "lines"
    });
    gsap.from(itallyText.lines, {
      opacity: 0,
      duration: 1.5, 
      x: 50, 
      scale:1.2, force3D:true,
      delay: 2
    })
  }, {
    scope: main
  })

  return (
    <div className="aboutus-page h-screen py-2 md:pt-4 lg:pt-4 md:pb-24 lg:pb-24 bg-[#0e0e0e]">
      <Header />
      <section  className="relative pt-20 md:pt-36 lg:pt-36 px-4 md:px-36 lg:px-36">
        <div className="w-full">
          <div  ref={main} className="hidden md:block lg:block relative w-full h-fit ">
            <BackgroundVideo 
              src={bgVideo}
              loop={true}
              autoPlay={true}
            />
            {/* <video ref={videoRef} className='absolute w-full h-full object-cover rounded-3xl' autoPlay playsInline loop muted >
              <source src={bgVideo} type="video/mp4"></source>
            </video> */}
            <div className="z-10 aboutus-title text-[150pt] leading-[160px] pb-3 uppercase font-semibold flex w-fit text-white bg-[#0e0e0e]">
              <span className="aboutus-title-span opacity-0 overflow-hidden">About us</span> 
            </div>
            
            <div className="z-10 grid grid-cols-3 gap-4">
              <div className="aboutus-detail text-[13pt] leading-[16pt] text-white text-start py-3 bg-[#0e0e0e] pr-3">
                <p className="font-bold pb-2">
                  Our agency embodies simplicity and sophistication.
                </p>
                <p className="py-2">
                  With a passion for visual storytelling, we transform ideas into impactful designs that resonate with clarity and purpose.
                </p>
                <p className="py-2">
                  At laconic, we believe in the power of minimalism to convey maximum impact, creating a visual language that speaks directly to the heart of your message.
                </p>
                <p className="py-2">
                  Join us on a journey where every pixel counts, and design is more than just an art, it's a powerful form of communication.
                </p>
              </div>
            </div>
            <div className="z-10 aboutus-bottom text-[18px] text-white italic absolute bottom-0 right-0 p-7 bg-[#0e0e0e] rounded-tl-3xl">
              Expressing much in few words
            </div>            
          </div>
          <div className="block md:hidden lg:hidden relative z-10 w-full h-full">
            <div className="text-[42pt] font-semibold uppercase text-center text-white pb-6">
              about us
            </div>
            <div className="text-[8pt] text-center text-white">
                <p className="py-2">
                  Our agency embodies simplicity and sophistication.
                </p>
                <p className="py-2">
                  With a passion for visual storytelling,<br /> we transform ideas into impactful designs<br /> that resonate with clarity and purpose.
                </p>
                <p className="py-2">
                  At laconic, we believe in the power of minimalism<br /> to convey maximum impact, creating a visual language<br /> that speaks directly to the heart of your message.
                </p>
                <p className="py-2">
                  Join us on a journey where every pixel counts,<br /> and design is more than just an art,<br /> it's a powerful form of communication.
                </p>
            </div>
            <div className="w-full h-16 py-5">
              <BackgroundVideo 
                src={bgVideo}
                loop={true}
                autoPlay={true}
              />
              {/* <video className='w-full h-16 object-cover rounded-md' autoPlay playsInline loop muted >
                <source src={bgVideo} type="video/mp4"></source>
              </video> */}
            </div>
          </div>
        </div>
      </section>
      <section className="relative pt-8 md:pt-4 lg:pt-4 pb-14 px-8 md:px-28 lg:px-28 bg-[#0e0e0e]">
        {/* <div className="w-full">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            freeMode={{
              enabled: true,
              momentumBounce: false,
              momentum: false,
              sticky: false,
            }}
            pagination={{
              clickable: true,
            }}
            speed={5000}
            loop={true}
            loopAddBlankSlides={true}
            loopAdditionalSlides={3}
            grabCursor={true}
            observer={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              stopOnLastSlide: false,
            }}
            modules={[Autoplay, FreeMode]}
            breakpoints={{
              // when window width is <= 499px
              499: {
                  slidesPerView: 1,
                  spaceBetweenSlides: 50
              },
              // when window width is <= 999px
              999: {
                  slidesPerView: 5,
                  spaceBetweenSlides: 50
              },
              // when window width is <= 2600px
              2600: {
                slidesPerView: 5,
                spaceBetweenSlides: 50
              }
            }
              
          }
          >
            {reviewlist.map((reviewItem, key) => (
              <SwiperSlide key={key}>
                <div className="review-item h-full flex flex-col justify-between text-left text-white text-[13px]">
                  <div className="w-full">
                    <div className="star_5 text-[30px]"></div>
                    <div className="review-detail mt-2 px-1">
                      {reviewItem.detail}
                    </div>
                  </div>
                  
                  <div className="review-user px-1 mt-2 font-semibold">
                    {reviewItem.name}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div> */}
        <Carousel responsive={responsive}
           additionalTransfrom={0}
           arrows={false}
           infinite={true}
           autoPlay={true}
           autoPlaySpeed={1}
           centerMode={false}
           className=""
           customTransition="all 5s linear"
           transitionDuration={5000}
        >
            {reviewlist.map((reviewItem, key) => (
              <div key={key} className="review-item h-full flex flex-col justify-between text-left text-white text-[13px]">
                <div className="w-full">
                  <div className="star_5 text-[30px]"></div>
                  <div className="review-detail mt-2 px-1">
                    {reviewItem.detail}
                  </div>
                </div>
                
                <div className="review-user px-1 mt-2 font-semibold">
                  {reviewItem.name}
                </div>
              </div>
            ))}
        </Carousel>
      </section>
      <Footer />
    </div>
  )
}