import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ThreedIcon } from "./icons/HeaderIcons";

export const HeaderBtn = ({iconPath, hoverIco, BtnName, timeOut, href}) => {
  const navigate = useNavigate();
  const [isHover, setIsHover] = useState(false)
  // const [gifIco, setGifIco] = useState('');
  let location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    console.log('useLocation ', location)
    if(location.pathname == href){
      setIsActive(true)
    }else{
      setIsActive(false);
    }
  }, [location]);


  const onHover = () => {
    setIsHover(true)
    // setGifIco(hoverIco);
  }
  const onLeave = () => {
    setIsHover(false)
    // setGifIco('');
  }
  useEffect(() => {
    if(isHover) {
      setTimeout(() => {
        // setGifIco(iconPath);
      }, timeOut)
    }
  }, [isHover])

  const onMenuClick = () => {
    navigate(href);
  }

  return (
      <Box 
        onMouseEnter={() => onHover()}
        onMouseLeave={() => onLeave()}
        onClick={() => onMenuClick()}
        className={isActive?"header-btn opacity-100" : "header-btn opacity-50 hover:opacity-100"}>
        {/* <img src={iconPath} className={!isHover? 'header-icon':'hidden'} alt="branding" />
        <img src={gifIco} className={isHover? "header-icon":'hidden'} alt="branding" /> */}
        {iconPath}
        <Typography className="header-IconBtn cursor-pointer">
          {BtnName}
        </Typography>
      </Box>
  )
}

export const HeaderMobileBtn = ({iconPath, BtnName, href }) => {
  let location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    console.log('useLocation ', location)
    if(location.pathname == href){
      setIsActive(true)
    }else{
      setIsActive(false);
    }
  }, [location]);

  

  return (
    <Box className="header-btn py-2">
      <img src={iconPath} className={isActive? ' cursor-pointer header-mobileIcon opacity-100':'header-mobileIcon'} alt="branding" />
      <Typography className="header-mobile-IconBtn animate-slideinright  cursor-pointer"
      >
        {BtnName}
      </Typography>
    </Box>
  )
}