import emailjs from '@emailjs/browser';

import { Container, Grid } from "@mui/material"
import bgVideo from '../assets/bg.mp4';
import { Header } from "../Components/Header";

import weatherIcon from "../assets/reach-icone/weather.svg";
import instaIcon from "../assets/reach-icone/instagram.svg";
import linkedIcon from "../assets/reach-icone/inkedin.svg";
import faceIcon from "../assets/reach-icone/facebok.svg";

import "./reachout.css"
import { useEffect, useRef, useState } from "react";

import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { SplitText, SteppedEase } from 'gsap/all';
import { WeatherSun } from "../Components/icons/WeatherIcons";
import {isMobile} from 'react-device-detect';
import { FacebookIcon, InstagramIcon, LinkedInIcon } from '../Components/icons/ReachIcons';


gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollSmoother, SplitText, SteppedEase);
const dateData = new Date();
export const Reachout = () => {
  const serviceID = "service_8m78137"
  const templateID = "template_mf0zlay"
  
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [message, setMessage] = useState('');


  const [countdownDate, setCountdownDate] = useState(`${String(dateData.getHours()).padStart(2, '0')}:${String(dateData.getMinutes()).padStart(2, '0')}:${String(dateData.getSeconds()).padStart(2, '0')}`);
  const main = useRef();
  const [isContact, setIsContact] = useState(false);
  useEffect(() => {
    const timeVal = () => {
      const event = new Date();
      console.log(event);
      setCountdownDate(`${String(event.getHours()).padStart(2, '0')}:${String(event.getMinutes()).padStart(2, '0')}:${String(event.getSeconds()).padStart(2, '0')}`);
    };
    const intervalId = setInterval(timeVal, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  useEffect(() => {
    const iconsObjs = gsap.utils.toArray(".reachout-headIcons");
    const headerText = new SplitText(".reachout-head", {type: "words, chars",
      wordDelimiter: "*",
      wordsClass: "word"
    });
    const posText = new SplitText(".reachout-location", {type: "lines"});
    gsap.to(".reachout-head", {opacity: 0});
    gsap.fromTo(".reach-top-left", { autoAlpha: 1, x: -1200 },
      { autoAlpha: 1, x: 0, duration: 0.5, 
        onComplete: () => {
          gsap.to(".reachout-head", {opacity: 1});
          gsap.from(headerText.chars, {
            duration: 0.5, 
            autoAlpha: 1,
            y: 300, 
            
            stagger: 0.05,
          });
          gsap.to(".reachout-location", {opacity: 1});
          gsap.from(posText.lines, {
            duration: 0.5,
            x: -200,
            stagger: 0.05,
            delay: 0.5,
          })
          iconsObjs.map(iconObj => {
            gsap.fromTo(iconObj, {
              scale: 0.5,
              autoAlpha: 0,
            }, {
              duration: 0.5,
              scale: 1,
              autoAlpha: 1,
              stagger: 0.05,
              delay: 0.5,
            })
          })
        }
      });
    gsap.fromTo(".reach-bottom-right", { autoAlpha: 1, y: 200 },
      { autoAlpha: 1, y: 0, duration: 0.5, delay: 0.5,
        onComplete: () => {
          gsap.fromTo('.touch-click', {
            duration: 0.5,
            autoAlpha: 0,
            clipPath: `inset(0px 0px 100%)`,
          }, {
            duration: 0.5,
            autoAlpha: 1,
            clipPath: `inset(0px 0px 0%)`,
            delay: 0.5,
          })
          gsap.fromTo(".contact-mail", {autoAlpha: 0, y: 100}, {
            duration: 0.5,
            y: 0,
            autoAlpha: 1,
            stagger: 0.05,
            delay: 0.5,
          })
        }
       });
  }, [])

  const onClickTouch = () => {
    gsap.to(".reach-bottom-right", {
      autoAlpha: 0,
      y: 200,
      duration: 0.5,
      onComplete: () => {
        setIsContact(true);
        setTimeout(() => {
          gsap.fromTo(".reach-contact", {
            y: 400,
            autoAlpha: 0
          }, {
            duration: 0.5,
            y: 0,
            autoAlpha: 1,
            onComplete: () => {
              const formElements = gsap.utils.toArray('.reach-element');
              formElements.map((elementItem, key) => {
                const labelElement = elementItem.querySelector('.reach-label-text')
                const inputElement = elementItem.querySelector('.reach-form-element')
                gsap.to(labelElement, {opacity: 1});
                const split = new SplitText(labelElement, { type: 'chars' })
                const typing_text = gsap.timeline()
                  .from(split.chars, {
                  duration: .01,
                  autoAlpha: 0,
                  delay: 0.5 * key,
                  stagger: {
                    each: .08,
                    onStart() {
                      let target = this.targets()[0];
                      console.log(target.offsetLeft + target.offsetWidth)
                      let cursorPos = target.offsetLeft + target.offsetWidth;
                      gsap.set('.cursor', {x: cursorPos + 3})
                    }
                  }
                });
                gsap.timeline().fromTo(inputElement, {autoAlpha: 0}, {autoAlpha: 1, duration: 0.5, delay: 0.5 * key})
              })
              gsap.fromTo('.reachout-send-btn', {autoAlpha: 0, scale: 0}, {
                autoAlpha: 1,
                scale: 1,
                duration: 0.5,
                delay: 2
              })
              // gsap.to('.reach-label-text', {
              //   text: {
              //     value: 'Whats your name?'
              //   }, 
              //   duration: 1,
              //   delay: 0.3, 
              //   ease: "none",
              // })
             
            }
          })
        }, 500)
        
      }
    })
    
  }

  const linkPrivacy = () => {
    window.open('https://laconic.org.uk/Laconic-Privacy-Policy.pdf', '_blank').focus();
  }

  const linkTerms = () => {
    window.open('https://laconic.org.uk/Laconic-Website-Terms.pdf', '_blank').focus();
  }

  const linkCookie = () => {
    window.open('https://laconic.org.uk/Laconic-Website-Cookie-Policy.pdf', '_blank').focus();
  }

  const openInstagram = () => {
    window.open('https://www.instagram.com/laconic.liverpool/', '_blank').focus();
  }

  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/company/laconic-art/', '_blank').focus();
  }

  const openFacebook = () => {
    window.open('https://www.facebook.com/laconic.art', '_blank').focus();
  }

  const contactFormSubmit = (e) => {
    e.preventDefault();
    if(e.target.checkValidity()){
      const templateData = {
        to_name: 'Laconic',
        from_name: userName,
        reply_to: email,
        message: message
      };
      emailjs.send(serviceID, templateID, templateData, {
            publicKey: 'Zzft-cD5kLJSs7L1J',
          })
          .then(
            (response) => {
              console.log('SUCCESS!', response.status, response.text);
            },
            (err) => {
              console.log('FAILED...', err);
            },
          );
    }
  }

  return (
    <div className="reachout-page h-screen bg-[#0e0e0e]">
      <Header />
      <section className="init-reachout w-full h-full flex justify-center items-start md:items-center lg:items-center">
        <Container ref={main} className=" px-8">
          {!isMobile &&<div className="hidden md:block lg:block relative overflow-hidden w-full h-[500px]">
            <video className='w-full h-[500px] object-cover rounded-3xl' autoPlay playsInline loop muted >
              <source src={bgVideo} type="video/mp4"></source>
            </video>
            <div className="reach-top-left pr-3 w-fit h-fit bg-[#0e0e0e] text-white ">
              <div className="relative w-fit h-fit z-10">
                <h1 className="reachout-head opacity-0 overflow-hidden text-[63pt] leading-[70px] font-extrabold uppercase">Reach out</h1>
                <div className="flex justify-between items-center py-3">
                  <div className="reachout-location opacity-0 flex flex-col justify-start items-start text-[15pt] uppercase">
                    united kingdom<br/>
                    livepool<br />
                    <b>{countdownDate}</b>
                  </div>
                  {/* <img className="reachout-headIcons opacity-0" src={weatherIcon} alt="weather"/> */}
                  <div className="reachout-headIcons opacity-0 w-24 h-24">
                    <WeatherSun />
                  </div>
                  <div className='reachout-headIcons w-[38px] h-[38px] opacity-0 cursor-pointer'  onClick={() => {openInstagram()}}>
                    <InstagramIcon />
                  </div>
                  <div className='reachout-headIcons w-[38px] h-[38px] opacity-0 cursor-pointer'  onClick={() => {openLinkedIn()}}>
                    <LinkedInIcon />
                  </div>
                  <div className='reachout-headIcons w-[38px] h-[38px] opacity-0 cursor-pointer' onClick={() => {openFacebook()}}>
                    <FacebookIcon />
                  </div>
                </div>
              </div>
            </div>
           {!isContact && 
            <div className="reach-bottom-right bg-[#0e0e0e] ">
              <div className="z-10 relative flex flex-col">
                <button onClick={() => {onClickTouch()}} className="touch-click py-4 px-14 opacity-0 text-[25pt] font-extralight">
                  <span className="mix-blend-difference">
                    click here to get in touch
                  </span> 
                </button>
                <span className="contact-mail opacity-0 text-white text-[15pt] font-extralight mt-4" >hello@laconic.uk</span>
              </div>
            </div>}

            {isContact && 
              <div className="reach-contact opacity-0 absolute top-[177px] text-white bg-[#0e0e0e] py-4">
                <Grid component={'form'} onSubmit={contactFormSubmit} container sx={{padding: '1px'}}>
                  <Grid item xs={6}>
                    <div className="reach-element w-full pr-4 text-start text-[14pt] font-light">
                      <span className="reach-label-text">Whats your name?</span>
                      <input type="text" value={userName} onChange={(e) => {setUserName(e.target.value)}} className="reach-form-element opacity-0 w-full" name="name" id="name" required/>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="reach-element w-full pl-4 text-start text-[14pt] font-light ">
                      <span className="reach-label-text">And your email?</span>
                      <input type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} className="reach-form-element opacity-0 w-full" name="email" id="email" required/>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="reach-element w-full text-start text-[14pt] font-light ">
                      <span className="reach-label-text">How can we help?</span>
                      <textarea className="reach-form-element opacity-0 w-full" value={message} onChange={(e) => {setMessage(e.target.value)}} name='message' rows={3} required/>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="w-full py-4">
                      <button className="reachout-send-btn opacity-0 text-[25pt]">
                        <span className="mix-blend-difference">
                          send
                        </span>
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
          </div>}


          {isMobile && <div className="block md:hidden lg:hidden relative w-full h-full py-36 px-[1px]">
              <div className="reach-mobile-top w-full pb-6 bg-[#0e0e0e]">
                <div className="relative w-fit h-fit z-10 mx-auto text-white">
                  <h1 className="reachout-head opacity-0 overflow-hidden text-[39pt] leading-[50px] font-extrabold uppercase">Reach out</h1>
                  <div className="flex justify-between items-center py-3">
                    <div className="reachout-location opacity-0 flex flex-col justify-start items-start text-[9pt] uppercase">
                      united kingdom<br/>
                      livepool<br />
                      <b>{countdownDate}</b>
                    </div>
                     <div className="reachout-headIcons opacity-0  w-14 h-14">
                      <WeatherSun />
                    </div>
                    <img className="reachout-headIcons w-6 opacity-0 cursor-pointer" onClick={() => {openInstagram()}} src={instaIcon} alt="insta"/>
                    <img className="reachout-headIcons w-6 opacity-0 cursor-pointer" onClick={() => {openLinkedIn()}} src={linkedIcon} alt="linked"/>
                    <img className="reachout-headIcons w-6 opacity-0 cursor-pointer" onClick={() => {openFacebook()}} src={faceIcon} alt="face"/>
                  </div>
                </div>
              </div>
              <video className='w-full h-[80px] object-cover rounded-3xl' autoPlay playsInline loop muted >
                <source src={bgVideo} type="video/mp4"></source>
              </video>
              <div className="reach-mobile-bottom w-full px-2 bg-[#0e0e0e]">
                {!isContact && <div className="z-10 relative flex flex-col  pt-6">
                  <button onClick={() => {onClickTouch()}} className="touch-click w-full px-8 py-4 opacity-0 text-[12pt] font-extralight">
                    <span className="mix-blend-difference">
                      click here to get in touch
                    </span> 
                  </button>
                  <span className="text-white text-[9pt] font-extralight mt-4" >hello@laconic.uk</span>
                </div>}
                {isContact && <div className="opacity-1 text-white bg-[#0e0e0e] py-4">
                  <Grid container sx={{padding: '1px'}}>
                    <Grid item xs={12}>
                      <div className="reach-element w-full text-start text-[9.62pt] font-light">
                        <span className="reach-label-text">Whats your name?</span>
                        <input type="text" className="reach-form-element opacity-0 w-full" name="name" id="name"  />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="reach-element w-full text-start text-[9.62pt] font-light ">
                        <span className="reach-label-text">And your email?</span>
                        <input type="text" className="reach-form-element opacity-0 w-full" name="email" id="email" />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="reach-element w-full text-start text-[9.62pt] font-light ">
                        <span className="reach-label-text">How can we help?</span>
                        <textarea className="reach-form-element opacity-0 w-full" rows={3}/>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="w-full py-4">
                        <button className="reachout-send-btn opacity-0 text-[15pt]">
                          <span className="mix-blend-difference">
                            send
                          </span>
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </div>}
              </div>
           
            {/* <div className="flex flex-col justify-between items-center reach-mobile-container py-32">
              
            </div> */}
            
          </div>}
        </Container>
      </section>
      <section className="hidden md:block lg:block reach-header w-full py-4 text-white text-[13pt] font-light fixed bottom-0">
        <span onClick={() => {linkPrivacy()}} className="px-3 cursor-pointer">
          Privacy Policy
        </span>
        <span onClick={() => {linkTerms()}} className="px-3 cursor-pointer">
          Terms & Conditions
        </span>
        <span onClick={() => {linkCookie()}} className="px-3 cursor-pointer">
          Cookie Policy
        </span>
      </section>
    </div>
  )
}