import React, { useLayoutEffect,  useEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import SearchlightProd1 from "../../assets/products/Visual-Media/Searchlight-1.png";
import SearchlightProd2 from "../../assets/products/Visual-Media/Searchlight-2.png";
import SearchlightProd3 from "../../assets/products/Visual-Media/Searchlight-3.png";

import MellowtoneProd1 from "../../assets/products/Visual-Media/Visual-Media-Mellowtone-1.png"
import MellowtoneProd2 from "../../assets/products/Visual-Media/Visual-Media-Mellowtone-2.png"
import MellowtoneProd3 from "../../assets/products/Visual-Media/Visual-Media-Mellowtone-3.png"

import AssortedProd1 from "../../assets/products/Visual-Media/Assorted-Visual-Media-1.png"
import AssortedProd2 from "../../assets/products/Visual-Media/Assorted-Visual-Media-2.png"
import AssortedProd3 from "../../assets/products/Visual-Media/Assorted-Visual-Media-3.png"

import "./services.css"
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const packageProsData = [
  {
    tagName: 'Searchlight Electric Ltd',
    bannerImg: SearchlightProd1,
    leftImg: SearchlightProd2,
    rightImg: SearchlightProd3,
    brief: `To superimpose lighting fittings, lamps, and home decor onto selected stock image sets. Ensuring seamless integration and realistic presentation to enhance the visual appeal of the scenes. The images were used in print and online.`,
    process: 'Starting with stock image selection, we skillfully integrated the lighting fixtures and home-wear into the scenes. Iterative adjustments and attention to detail ensured a realistic image, resulting in visually enhanced images that met and exceeded the clients expectations.',
    process1: '',
    review: `The team flawlessly integrated lighting fittings, lamps, and decor into selected stock images, creating visually stunning scenes for both print and online use. Their attention to detail and seamless execution elevated the realism, enhancing the overall visual appeal. Highly satisfied with their work—exceeded our expectations!`,
    reviewUser: `Searchlight Electric Ltd, Manchester`,
    reserved: false,
  },
  {
    tagName: 'Mellowtone',
    bannerImg: MellowtoneProd1,
    leftImg: MellowtoneProd2,
    rightImg: MellowtoneProd3,
    brief: `A series of posters and printed materials for Mellowtone Records, a label born from the spirit of Mellowtone nights in Liverpool, UK. We captured the essence of diversity of their artists in folk, blues, roots, and related genres.`,
    process: 'Beginning with a comprehensive understanding of Mellowtone’s rich history, we crafted posters and printed materials that encapsulated the soul, magic, and alchemy of their musical mission. Each design reflected the spirit of their events, effectively showcasing their unique musical narrative to a wider audience.',
    process1: '',
    review: `laconic. have been a pleasure to work with. They took elements of our existing design, branding and illustration and modified them for various uses and platforms, including online graphics/adverts, and print materials such as flyers and posters. These visual elements perfectly aligned with our brand identity, creating beautiful and effective design.`,
    reviewUser: ``,
    reserved: true,
  },
  {
    tagName: 'Assorted Visual Media',
    bannerImg: AssortedProd1,
    leftImg: AssortedProd2,
    rightImg: AssortedProd3,
    brief: `We generated a range of visual content to portray each client’s message and tone through graphic media. Our emphasis is on nurturing creativity and ensuring harmony with the specified brief or brand identity, aiming to deliver visually engaging assets that resonate effectively with our clients’ target demographic.`,
    process: `We commence with a comprehensive understanding of the client’s vision, we utilise engaging and unique graphics to bring concepts to life. Iterative feedback sessions ensured the illustrations met client expectations.`,
    process1: '',
    review: `Our agency delivered an array of exceptional visual media projects, showcasing creativity and precision. From captivating illustrations to immersive 3D renders, each project reflected our commitment to excellence. Client visions were not just met but exceeded, establishing us as a go-to for impactful and innovative visual solutions.`,
    reviewUser: ``,
    reserved: false,
  },
]

export const Visualmedia = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])


    return (
        <div className="service-pages bg-[#0e0e0e] py-2 md:pt-4 lg:pt-4 md:pb-32 lg:pb-32">
          <Header />
          {packageProsData.map((packageData, key) => (
            <ServiceSection 
            key={key}
            pageIndex={key}
            isScrollAnimation={key !== 0} reserved={packageData.reserved}
            bannerImg={packageData.bannerImg} prod1={packageData.leftImg} prod2={packageData.rightImg}
            tagName={packageData.tagName} brief={packageData.brief} process={packageData.process} 
            process1={packageData.process1} review={packageData.review} reviewuser={packageData.reviewUser}
            />
          ))}
          <Footer />
        </div>
      )
}