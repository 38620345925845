import { useEffect, useRef } from "react"

export const ThreedIcon = ({width, height, isHover}) => {
    const motionRef = useRef();
    useEffect(() => {
        if(isHover){
            const animateArr = motionRef.current.querySelectorAll('animate');
            const motionsArr = motionRef.current.querySelectorAll('animateMotion');
            const transformsArr = motionRef.current.querySelectorAll('animateTransform');
            console.log(motionsArr)
            animateArr.forEach((element) => {
                element.beginElement();
              });
            motionsArr.forEach((element) => {
                element.beginElement();
              });
              transformsArr.forEach((element) => {
                element.beginElement();
              });
        }
    }, [isHover])
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width={width} height={height} viewBox="0 0 500 500" >
            <defs ref={motionRef}>
                <animateMotion repeatCount="0" dur="4s" begin="0s" href="#Three_R_G_L_1_G" fill="freeze" keyTimes="0;0.25;0.5;0.75;1" path="M185 314 C185,314 185,184 185,184 C185,184 313,184 313,184 C313,184 313,314 313,314 C313,314 185,314 185,314 " keyPoints="0;0.25;0.5;0.75;1" keySplines="0.204 0 0.814 1;0.204 0 0.814 1;0.204 0 0.814 1;0.204 0 0.814 1" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="4s" begin="0s" href="#Three_R_G_L_1_G" fill="freeze" attributeName="transform" from="1.08 1.08" to="1.08 1.08" type="scale" additive="sum" keyTimes="0;1" values="1.08 1.08;1.08 1.08" keySplines="0 0 1 1" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="4s" begin="0s" href="#Three_R_G_L_1_G" fill="freeze" attributeName="transform" from="65 -64" to="65 -64" type="translate" additive="sum" keyTimes="0;1" values="65 -64;65 -64" keySplines="0 0 1 1" calcMode="spline"/>
                <animateMotion repeatCount="0" dur="4s" begin="0s" href="#Three_R_G_L_0_G" fill="freeze" keyTimes="0;0.25;0.5;0.75;1" path="M313 184 C313,184 313,314 313,314 C313,314 185,314 185,314 C185,314 185,184 185,184 C185,184 313,184 313,184 " keyPoints="0;0.25;0.5;0.75;1" keySplines="0.204 0 0.814 1;0.204 0 0.814 1;0.204 0 0.814 1;0.204 0 0.814 1" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="4s" begin="0s" href="#Three_R_G_L_0_G" fill="freeze" attributeName="transform" from="1.08 1.08" to="1.08 1.08" type="scale" additive="sum" keyTimes="0;1" values="1.08 1.08;1.08 1.08" keySplines="0 0 1 1" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="4s" begin="0s" href="#Three_R_G_L_0_G" fill="freeze" attributeName="transform" from="65 -64" to="65 -64" type="translate" additive="sum" keyTimes="0;1" values="65 -64;65 -64" keySplines="0 0 1 1" calcMode="spline"/>
                <animate attributeType="XML" attributeName="opacity" dur="4s" from="0" to="1" href="#time_group"/>
            </defs>
            <g id="Three_R_G">
                <g id="Three_R_G_L_1_G">
                    <path id="Three_R_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="12" strokeOpacity="1" d=" M51 15 C51,15 51,113 51,113 C51,149.98 20.98,180 -16,180 C-16,180 -114,180 -114,180 C-150.98,180 -181,149.98 -181,113 C-181,113 -181,15 -181,15 C-181,-21.98 -150.98,-52 -114,-52 C-114,-52 -16,-52 -16,-52 C20.98,-52 51,-21.98 51,15z "/>
                </g>
                <g id="Three_R_G_L_0_G">
                    <path id="Three_R_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="12" strokeOpacity="1" d=" M51 15 C51,15 51,113 51,113 C51,149.98 20.98,180 -16,180 C-16,180 -114,180 -114,180 C-150.98,180 -181,149.98 -181,113 C-181,113 -181,15 -181,15 C-181,-21.98 -150.98,-52 -114,-52 C-114,-52 -16,-52 -16,-52 C20.98,-52 51,-21.98 51,15z "/>
                </g>
            </g>
            <g id="time_group"/>
        </svg>
    )
}

export const IllustrationIcon = ({width, height, isHover}) => {
    const motionRef = useRef();
    useEffect(() => {
        if(isHover){
            const animateArr = motionRef.current.querySelectorAll('animate');
            const motionsArr = motionRef.current.querySelectorAll('animateMotion');
            const transformsArr = motionRef.current.querySelectorAll('animateTransform');
            console.log(motionsArr)
            animateArr.forEach((element) => {
                element.beginElement();
              });
            motionsArr.forEach((element) => {
                element.beginElement();
              });
              transformsArr.forEach((element) => {
                element.beginElement();
              });
        }
    }, [isHover])
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width={width} height={height} viewBox="0 0 500 500">
            <defs ref={motionRef}>
                <animateTransform repeatCount="0" dur="2.8s" begin="0s" xlinkHref="#iLL_R_G_L_0_G_L_2_G" fill="freeze" attributeName="transform" from="0" to="0" type="rotate" additive="sum" keyTimes="0;0.1190476;0.3690476;0.7142857;0.952381;1" values="0;0;-90;-90;0;0" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="2.8s" begin="0s" xlinkHref="#iLL_R_G_L_0_G_L_2_G" fill="freeze" attributeName="transform" from="0.9500000000000001 0.9500000000000001" to="0.9500000000000001 0.9500000000000001" type="scale" additive="sum" keyTimes="0;1" values="0.9500000000000001 0.9500000000000001;0.9500000000000001 0.9500000000000001" keySplines="0 0 1 1" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="2.8s" begin="0s" xlinkHref="#iLL_R_G_L_0_G_L_2_G" fill="freeze" attributeName="transform" from="-0.625 0.203" to="-0.625 0.203" type="translate" additive="sum" keyTimes="0;1" values="-0.625 0.203;-0.625 0.203" keySplines="0 0 1 1" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="2.8s" begin="0s" xlinkHref="#iLL_R_G_L_0_G_L_1_G_N_3_T_0" fill="freeze" attributeName="transform" from="0" to="0" type="rotate" additive="sum" keyTimes="0;0.1190476;0.3690476;0.7142857;0.952381;1" values="0;0;-90;-90;0;0" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="2.8s" begin="0s" xlinkHref="#iLL_R_G_L_0_G_L_1_G_N_3_T_0" fill="freeze" attributeName="transform" from="0.9500000000000001 0.9500000000000001" to="0.9500000000000001 0.9500000000000001" type="scale" additive="sum" keyTimes="0;1" values="0.9500000000000001 0.9500000000000001;0.9500000000000001 0.9500000000000001" keySplines="0 0 1 1" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="2.8s" begin="0s" xlinkHref="#iLL_R_G_L_0_G_L_1_G_N_3_T_0" fill="freeze" attributeName="transform" from="-0.625 0.203" to="-0.625 0.203" type="translate" additive="sum" keyTimes="0;1" values="-0.625 0.203;-0.625 0.203" keySplines="0 0 1 1" calcMode="spline"/>
                <animate attributeType="XML" attributeName="opacity" dur="3s" from="0" to="1" xlinkHref="#time_group"/>
            </defs>
            <g id="iLL_R_G">
                <g id="iLL_R_G_L_0_G" transform=" scale(1.04, 1.04)">
                    <g id="iLL_R_G_L_0_G_L_2_G" transform=" translate(250, 250)">
                        <path id="iLL_R_G_L_0_G_L_2_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="21" strokeOpacity="1" d=" M36 -154 C36,-154 -1,-198 -38.25,-151.75 C-71.25,-96.25 -189.25,112.75 -189.25,112.75 C-189.25,112.75 -206.5,171.5 -145.5,171.75 C-84.5,172 -124.5,172.5 -118.5,172.5 C-112.5,172.5 160,171 160,171 C160,171 204,161 190,110 C162,63.5 36,-154 36,-154z "/>
                    </g>
                    <g id="iLL_R_G_L_0_G_L_1_G_N_3_T_0" transform=" translate(250, 250)">
                        <g id="iLL_R_G_L_0_G_L_1_G" transform=" translate(0, 4) scale(0.95, 0.95) translate(0, 0)">
                            <path id="iLL_R_G_L_0_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="21" strokeOpacity="1" d=" M-89 -65.75 C-89,-65.75 89.5,-65.75 89.5,-65.75 "/>
                        </g>
                    </g>
                    <g id="iLL_R_G_L_0_G_L_0_G" transform=" translate(250, 250) scale(0.95, 0.95) translate(0, 0)">
                        <path id="iLL_R_G_L_0_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="21" strokeOpacity="1" d=" M-109.5 -132 C-109.5,-132 -180.6,-80.76 -172.1,2.24 "/>
                    </g>
                </g>
            </g>
            <g id="time_group"/>
        </svg>
    )
}

export const WebIcon = ({width, height, isHover}) => {
    const motionRef = useRef();
    useEffect(() => {
        if(isHover){
            const animateArr = motionRef.current.querySelectorAll('animate');
            const motionsArr = motionRef.current.querySelectorAll('animateMotion');
            const transformsArr = motionRef.current.querySelectorAll('animateTransform');
            console.log(motionsArr)
            animateArr.forEach((element) => {
                element.beginElement();
              });
            motionsArr.forEach((element) => {
                element.beginElement();
              });
              transformsArr.forEach((element) => {
                element.beginElement();
              });
        }
    }, [isHover])
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width={width} height={height} viewBox="0 0 500 500">
            <defs ref={motionRef}>
                <animateMotion repeatCount="0" dur="4.5333333s" begin="0s" xlinkHref="#Web_R_G_L_5_G" fill="freeze" keyTimes="0;0.3235294;0.6617647;0.9926471;1" path="M252.5 179.5 C252.5,179.5 252.5,179.5 252.5,179.5 C252.5,179.5 252.5,340.5 252.5,340.5 C252.5,340.5 252.5,179.5 252.5,179.5 C252.5,179.5 252.5,179.5 252.5,179.5 " keyPoints="0;0;0.5;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline"/>
                <animateTransform repeatCount="0" dur="4.5333333s" begin="0s" xlinkHref="#Web_R_G_L_5_G" fill="freeze" attributeName="transform" from="-2.5 73" to="-2.5 73" type="translate" additive="sum" keyTimes="0;1" values="-2.5 73;-2.5 73" keySplines="0 0 1 1" calcMode="spline"/>
                <animate repeatCount="0" dur="4.5333333s" begin="0s" xlinkHref="#Web_R_G_L_4_G_M" fill="freeze" attributeName="opacity" from="1" to="0" keyTimes="0;0.4558824;0.4558824;1" values="1;1;0;0" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline"/>
                <animate repeatCount="0" dur="4.5333333s" begin="0s" xlinkHref="#Web_R_G_L_3_G_M" fill="freeze" attributeName="opacity" from="0" to="0" keyTimes="0;0.0735294;0.0735301;0.5;0.5;1" values="0;0;1;1;0;0" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline"/>
                <animate repeatCount="0" dur="4.5333333s" begin="0s" xlinkHref="#Web_R_G_L_2_G_M" fill="freeze" attributeName="opacity" from="0" to="0" keyTimes="0;0.1397059;0.1397066;0.5441176;0.5441177;1" values="0;0;1;1;0;0" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline"/>
                <animate repeatCount="0" dur="4.5333333s" begin="0s" xlinkHref="#Web_R_G_L_1_G_M" fill="freeze" attributeName="opacity" from="0" to="0" keyTimes="0;0.2058824;0.2058831;0.6102941;0.6102941;1" values="0;0;1;1;0;0" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline"/>
                <animate attributeType="XML" attributeName="opacity" dur="5s" from="0" to="1" xlinkHref="#time_group"/>
            </defs>
            <g id="Web_R_G">
                <g id="Web_R_G_L_5_G">
                    <path id="Web_R_G_L_5_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="17" strokeOpacity="1" d=" M-172 -73 C-172,-73 177,-73 177,-73 "/>
                </g>
                <g id="Web_R_G_L_4_G_M">
                    <g id="Web_R_G_L_4_G" transform=" translate(252.5, 238.76) translate(-2.5, 73)">
                        <path id="Web_R_G_L_4_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="17" strokeOpacity="1" d=" M-125.5 -73.24 C-125.5,-73.24 134,-73.24 134,-73.24 "/>
                    </g>
                </g>
                <g id="Web_R_G_L_3_G_M">
                    <g id="Web_R_G_L_3_G" transform=" translate(252.5, 271.76) translate(-2.5, 73)">
                        <path id="Web_R_G_L_3_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="17" strokeOpacity="1" d=" M-125.5 -73.24 C-125.5,-73.24 134,-73.24 134,-73.24 "/>
                    </g>
                </g>
                <g id="Web_R_G_L_2_G_M">
                    <g id="Web_R_G_L_2_G" transform=" translate(252.5, 301.76) translate(-2.5, 73)">
                        <path id="Web_R_G_L_2_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="17" strokeOpacity="1" d=" M-125.5 -73.24 C-125.5,-73.24 134,-73.24 134,-73.24 "/>
                    </g>
                </g>
                <g id="Web_R_G_L_1_G_M">
                    <g id="Web_R_G_L_1_G" transform=" translate(252.5, 334.76) translate(-2.5, 73)">
                        <path id="Web_R_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="17" strokeOpacity="1" d=" M-125.5 -73.24 C-125.5,-73.24 134,-73.24 134,-73.24 "/>
                    </g>
                </g>
                <g id="Web_R_G_L_0_G" transform=" translate(250, 250) scale(1.5, 1.5) translate(65, -64)">
                    <path id="Web_R_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="12" strokeOpacity="1" d=" M51 15 C51,15 51,113 51,113 C51,149.98 20.98,180 -16,180 C-16,180 -114,180 -114,180 C-150.98,180 -181,149.98 -181,113 C-181,113 -181,15 -181,15 C-181,-21.98 -150.98,-52 -114,-52 C-114,-52 -16,-52 -16,-52 C20.98,-52 51,-21.98 51,15z "/>
                </g>
            </g>
            <g id="time_group"/>
        </svg>
    )
}

export const VisualMediaIcon = ({width, height, isHover}) => {
    // const motionRef = useRef();
    // useEffect(() => {
    //     if(isHover){
    //         const animateArr = motionRef.current.querySelectorAll('animate');
    //         const motionsArr = motionRef.current.querySelectorAll('animateMotion');
    //         const transformsArr = motionRef.current.querySelectorAll('animateTransform');
    //         console.log(motionsArr)
    //         animateArr.forEach((element) => {
    //             element.beginElement();
    //           });
    //         motionsArr.forEach((element) => {
    //             element.beginElement();
    //           });
    //           transformsArr.forEach((element) => {
    //             element.beginElement();
    //           });
    //     }
    // }, [isHover])
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width={width} height={height} viewBox="0 0 500 500">
            <defs>
                <animate attributeType="XML" attributeName="opacity" dur="2s" from="0" to="1" xlinkHref="#time_group"/>
            </defs>
            <g id="_R_G">
                <g id="_R_G_L_2_G" transform=" translate(250, 250) translate(0, 0)">
                    <path id="_R_G_L_2_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="15" strokeOpacity="1" d=" M-149.75 -149.5 C-149.75,-149.5 150,149.63 150,149.63 "/>
                </g>
                <g id="_R_G_L_1_G">
                    <g id="_R_G_L_1_G_L_1_G" transform=" translate(250, 250) translate(0, 0)">
                        <path id="_R_G_L_1_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="15" strokeOpacity="1" d=" M-109.5 -109 C-109.5,-109 -180.75,-27.25 -129.75,30.25 C-78.75,87.75 -9.54,45.79 19.5,21.5 C47,-1.5 105,-36.5 152,-6 C199,24.5 202.5,94.5 152,149.5 "/>
                    </g>
                    <g id="_R_G_L_1_G_L_0_G" transform=" translate(290.443, 290.997) rotate(90) scale(-1, 1) translate(0, 0)">
                        <path id="_R_G_L_1_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="15" strokeOpacity="1" d=" M-109.5 -109 C-109.5,-109 -180.75,-27.25 -129.75,30.25 C-78.75,87.75 -9.54,45.79 19.5,21.5 C47,-1.5 105,-36.5 152,-6 C199,24.5 202.5,94.5 152,149.5 "/>
                    </g>
                    </g>
                    <g id="_R_G_L_0_G" transform=" translate(207.979, 208.004) translate(-250, -250)">
                    <g id="_R_G_L_0_G_L_1_G" transform=" translate(250, 250) translate(0, 0)">
                        <path id="_R_G_L_0_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="15" strokeOpacity="1" d=" M-109.5 -109 C-109.5,-109 -180.75,-27.25 -129.75,30.25 C-78.75,87.75 -9.54,45.79 19.5,21.5 C47,-1.5 105,-36.5 152,-6 C199,24.5 202.5,94.5 152,149.5 "/>
                    </g>
                    <g id="_R_G_L_0_G_L_0_G" transform=" translate(290.443, 290.997) rotate(90) scale(-1, 1) translate(0, 0)">
                        <path id="_R_G_L_0_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="15" strokeOpacity="1" d=" M-109.5 -109 C-109.5,-109 -180.75,-27.25 -129.75,30.25 C-78.75,87.75 -9.54,45.79 19.5,21.5 C47,-1.5 105,-36.5 152,-6 C199,24.5 202.5,94.5 152,149.5 "/>
                    </g>
                </g>
            </g>
            <g id="time_group"/>
        </svg>
    )
}

export const PresentationsIcon = ({width, height, isHover}) => {
    const motionRef = useRef();
    useEffect(() => {
        if(isHover){
            const animateArr = motionRef.current.querySelectorAll('animate');
            const motionsArr = motionRef.current.querySelectorAll('animateMotion');
            const transformsArr = motionRef.current.querySelectorAll('animateTransform');
            console.log(motionsArr)
            animateArr.forEach((element) => {
                element.beginElement();
              });
            motionsArr.forEach((element) => {
                element.beginElement();
              });
              transformsArr.forEach((element) => {
                element.beginElement();
              });
        }
    }, [isHover])
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width={width} height={height} viewBox="0 0 500 500" >
            <defs ref={motionRef}>
                <animateMotion repeatCount="0" dur="4.6s" begin="0s" xlinkHref="#Present_R_G_L_0_G" fill="freeze" keyTimes="0;0.0724638;0.2898551;0.3623188;0.5217391;0.5724638;0.7753623;1" path="M244 219 C244,219 244,219 244,219 C244,219 252,247 252,247 C252,247 252,247 252,247 C252,247 244,219 244,219 C244,219 244,219 244,219 C244,219 377,219 377,219 C377,219 244,219 244,219 " keyPoints="0;0;0.09;0.09;0.18;0.18;0.59;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0.333 0.667 0.667;0.333 0 0.833 1;0.333 0.333 0.833 0.833;0.333 0 0.833 1;0.333 0 0.833 1" calcMode="spline" />
                <animateTransform repeatCount="0" dur="4.6s" begin="0s" xlinkHref="#Present_R_G_L_0_G" fill="freeze" attributeName="transform" from="2.23 2.23" to="2.23 2.23" type="scale" additive="sum" keyTimes="0;0.0724638;0.2898551;0.3623188;0.5217391;0.5724638;1" values="2.23 2.23;2.23 2.23;1.11 1.11;1.11 1.11;2.23 2.23;2.23 2.23;2.23 2.23" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.167 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateTransform repeatCount="0" dur="4.6s" begin="0s" xlinkHref="#Present_R_G_L_0_G" fill="freeze" attributeName="transform" from="-250 -250" to="-250 -250" type="translate" additive="sum" keyTimes="0;1" values="-250 -250;-250 -250" keySplines="0 0 1 1" calcMode="spline" />
                <animate attributeType="XML" attributeName="opacity" dur="5s" from="0" to="1" xlinkHref="#time_group" />
            </defs>
            <g id="Present_R_G">
                <g id="Present_R_G_L_2_G" transform=" translate(250, 250) translate(-1.5, 0.5)">
                <path id="Present_R_G_L_2_G_D_0_P_0" fill="#cb0707" fillOpacity="1" fillRule="nonzero" d=" M1.5 -178 C99.46,-178 179,-98.46 179,-0.5 C179,97.46 99.46,177 1.5,177 C-96.46,177 -176,97.46 -176,-0.5 C-176,-98.46 -96.46,-178 1.5,-178z " />
                <path id="Present_R_G_L_2_G_D_1_P_0" stroke="#ff0000" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="14" strokeOpacity="1" d=" M1.5 -178 C99.46,-178 179,-98.46 179,-0.5 C179,97.46 99.46,177 1.5,177 C-96.46,177 -176,97.46 -176,-0.5 C-176,-98.46 -96.46,-178 1.5,-178z " />
                </g>
                <g id="Present_R_G_L_1_G" transform=" translate(250, 250) translate(-1.5, 0.5)">
                <path id="Present_R_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="14" strokeOpacity="1" d=" M1.5 -178 C99.46,-178 179,-98.46 179,-0.5 C179,97.46 99.46,177 1.5,177 C-96.46,177 -176,97.46 -176,-0.5 C-176,-98.46 -96.46,-178 1.5,-178z " />
                </g>
                <g id="Present_R_G_L_0_G">
                <g id="Present_R_G_L_0_G_L_2_G" transform=" translate(250, 250) translate(-1.5, 0.5)">
                    <path id="Present_R_G_L_0_G_L_2_G_D_0_P_0" fill="#cb0707" fillOpacity="1" fillRule="nonzero" d=" M1.5 -178 C99.46,-178 179,-98.46 179,-0.5 C179,97.46 99.46,177 1.5,177 C-96.46,177 -176,97.46 -176,-0.5 C-176,-98.46 -96.46,-178 1.5,-178z " />
                    <path id="Present_R_G_L_0_G_L_2_G_D_1_P_0" stroke="#ff0000" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="14" strokeOpacity="1" d=" M1.5 -178 C99.46,-178 179,-98.46 179,-0.5 C179,97.46 99.46,177 1.5,177 C-96.46,177 -176,97.46 -176,-0.5 C-176,-98.46 -96.46,-178 1.5,-178z " />
                </g>
                <g id="Present_R_G_L_0_G_L_1_G">
                    <g id="Present_R_G_L_0_G_L_1_G_L_5_G" transform=" translate(252, 236) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_1_G_L_5_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_1_G_L_4_G" transform=" translate(252, 294) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_1_G_L_4_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_1_G_L_3_G" transform=" translate(252, 119) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_1_G_L_3_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_1_G_L_2_G" transform=" translate(252, 177) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_1_G_L_2_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_1_G_L_1_G" transform=" translate(252, 354) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_1_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_1_G_L_0_G" transform=" translate(252, 412) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_1_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                </g>
                <g id="Present_R_G_L_0_G_L_0_G" transform=" translate(266, 250) rotate(90) translate(-250, -250)">
                    <g id="Present_R_G_L_0_G_L_0_G_L_5_G" transform=" translate(252, 236) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_0_G_L_5_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_0_G_L_4_G" transform=" translate(252, 294) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_0_G_L_4_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_0_G_L_3_G" transform=" translate(252, 119) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_0_G_L_3_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_0_G_L_2_G" transform=" translate(252, 177) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_0_G_L_2_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_0_G_L_1_G" transform=" translate(252, 354) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_0_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                    <g id="Present_R_G_L_0_G_L_0_G_L_0_G" transform=" translate(252, 412) translate(-2, 15)">
                    <path id="Present_R_G_L_0_G_L_0_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="6" strokeOpacity="1" d=" M-182 -15 C-182,-15 186,-15 186,-15 " />
                    </g>
                </g>
                </g>
            </g>
            <g id="time_group" />
        </svg>
    )
}

export const PackagingIcon = ({width, height, isHover}) => {
    const motionRef = useRef();
    useEffect(() => {
        if(isHover){
            const animateArr = motionRef.current.querySelectorAll('animate');
            const motionsArr = motionRef.current.querySelectorAll('animateMotion');
            const transformsArr = motionRef.current.querySelectorAll('animateTransform');
            console.log(motionsArr)
            animateArr.forEach((element) => {
                element.beginElement();
              });
            motionsArr.forEach((element) => {
                element.beginElement();
              });
              transformsArr.forEach((element) => {
                element.beginElement();
              });
        }
    }, [isHover])
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width={width} height={height} viewBox="0 0 500 500">
            <defs ref={motionRef}>
                <animate repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_2_G_D_0_P_0" fill="freeze" attributeName="width" attributeType="XML" from="348" to="0" keyTimes="0;0.5;1" values="348;0;0" keySplines="0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animate repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_2_G_D_0_P_0" fill="freeze" attributeName="height" attributeType="XML" from="181" to="181" keyTimes="0;0.5;1" values="181;181;181" keySplines="0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateTransform repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_2_G_D_0_P_0" fill="freeze" attributeName="transform" from="-174 -90.5" to="0 -90.5" type="translate" additive="sum" keyTimes="0;0.5;1" values="-174 -90.5;0 -90.5;0 -90.5" keySplines="0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateMotion repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_2_G" fill="freeze" keyTimes="0;0.5;1" path="M250 250 C250,250 422.5,250 422.5,250 C422.5,250 422.5,250 422.5,250 " keyPoints="0;1;1" keySplines="0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateTransform repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_2_G" fill="freeze" attributeName="transform" from="174.5 -0.5" to="174.5 -0.5" type="translate" additive="sum" keyTimes="0;1" values="174.5 -0.5;174.5 -0.5" keySplines="0 0 1 1" calcMode="spline" />
                <animate repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_1_G_D_0_P_0" fill="freeze" attributeName="width" attributeType="XML" from="0" to="347" keyTimes="0;0.5;1" values="0;0;347" keySplines="0.333 0 0.667 1;0.333 0 0.667 1" calcMode="spline" />
                <animate repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_1_G_D_0_P_0" fill="freeze" attributeName="height" attributeType="XML" from="181" to="181" keyTimes="0;0.5;1" values="181;181;181" keySplines="0.333 0 0.667 1;0.333 0 0.667 1" calcMode="spline" />
                <animateTransform repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_1_G_D_0_P_0" fill="freeze" attributeName="transform" from="0 -90.5" to="-173.5 -90.5" type="translate" additive="sum" keyTimes="0;0.5;1" values="0 -90.5;0 -90.5;-173.5 -90.5" keySplines="0.333 0 0.667 1;0.333 0 0.667 1" calcMode="spline" />
                <animateMotion repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_1_G" fill="freeze" keyTimes="0;0.5;1" path="M76.5 250 C76.5,250 76.5,250 76.5,250 C76.5,250 250,250 250,250 " keyPoints="0;0;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1" calcMode="spline" />
                <animateTransform repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_1_G" fill="freeze" attributeName="transform" from="174.5 -0.5" to="174.5 -0.5" type="translate" additive="sum" keyTimes="0;1" values="174.5 -0.5;174.5 -0.5" keySplines="0 0 1 1" calcMode="spline" />
                <animate repeatCount="0" dur="3s" begin="0s" xlinkHref="#Package_R_G_L_1_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.5;0.5000011;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
                <animate attributeType="XML" attributeName="opacity" dur="3s" from="0" to="1" xlinkHref="#time_group" />
            </defs>
            <g id="Package_R_G">
                <g id="Package_R_G_L_2_G">
                <g id="Package_R_G_L_2_G_D_0_P_0_G_0_T_0" transform=" translate(-174.5, 0.5) translate(0, 0)">
                    <rect id="Package_R_G_L_2_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="12" strokeOpacity="1" x="0" y="0" />
                </g>
                </g>
                <g id="Package_R_G_L_1_G_M">
                <g id="Package_R_G_L_1_G">
                    <g id="Package_R_G_L_1_G_D_0_P_0_G_0_T_0" transform=" translate(-174.5, 0.5) translate(0, 0)">
                    <rect id="Package_R_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="12" strokeOpacity="1" x="0" y="0" />
                    </g>
                </g>
                </g>
                <g id="Package_R_G_L_0_G" transform=" translate(250, 250) scale(1.5, 1.5) translate(65, -64)">
                <path id="Package_R_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="12" strokeOpacity="1" d=" M51 15 C51,15 51,113 51,113 C51,149.98 20.98,180 -16,180 C-16,180 -114,180 -114,180 C-150.98,180 -181,149.98 -181,113 C-181,113 -181,15 -181,15 C-181,-21.98 -150.98,-52 -114,-52 C-114,-52 -16,-52 -16,-52 C20.98,-52 51,-21.98 51,15z " />
                </g>
            </g>
            <g id="time_group" />
        </svg>
    )
}

export const BrandingIcon = ({width, height, isHover}) => {
    const motionRef = useRef();
    useEffect(() => {
        if(isHover){
            const animateArr = motionRef.current.querySelectorAll('animate');
            const motionsArr = motionRef.current.querySelectorAll('animateMotion');
            const transformsArr = motionRef.current.querySelectorAll('animateTransform');
            console.log(motionsArr)
            animateArr.forEach((element) => {
                element.beginElement();
            });
            motionsArr.forEach((element) => {
                element.beginElement();
            });
            transformsArr.forEach((element) => {
                element.beginElement();
            });
        }
    }, [isHover])
    // const pauseAnimation = (e)=>{
    //     e.target.pauseAnimations();
    // }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width={width} height={height} viewBox="0 0 500 500" >
            <defs ref={motionRef}>
                <animate repeatCount="0" dur="2.0333333s" xlinkHref="#Brand_R_G_L_1_G_D_0_P_0" fill="freeze" attributeName="width" attributeType="XML" from="76" to="76" keyTimes="0;0.4918033;0.9836066;1" values="76;76;76;76" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animate repeatCount="0" dur="2.0333333s"  xlinkHref="#Brand_R_G_L_1_G_D_0_P_0" fill="freeze" attributeName="height" attributeType="XML" from="257" to="257" keyTimes="0;0.4918033;0.9836066;1" values="257;76;257;257" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateTransform repeatCount="0" dur="2.0333333s"  xlinkHref="#Brand_R_G_L_1_G_D_0_P_0" fill="freeze" attributeName="transform" from="-38 -128.5" to="-38 -128.5" type="translate" additive="sum" keyTimes="0;0.4918033;0.9836066;1" values="-38 -128.5;-38 -38;-38 -128.5;-38 -128.5" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateMotion repeatCount="0" dur="2.0333333s" xlinkHref="#Brand_R_G_L_1_G" fill="freeze" keyTimes="0;0.4918033;0.9836066;1" path="M188 394.5 C188,394.5 188,485.5 188,485.5 C188,485.5 188,394.5 188,394.5 C188,394.5 188,394.5 188,394.5 " keyPoints="0;0.5;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateTransform repeatCount="0" dur="2.0333333s" xlinkHref="#Brand_R_G_L_1_G" fill="freeze" attributeName="transform" from="61 -142" to="61 -142" type="translate" additive="sum" keyTimes="0;1" values="61 -142;61 -142" keySplines="0 0 1 1" calcMode="spline" />
                <animate repeatCount="0" dur="2.0333333s" xlinkHref="#Brand_R_G_L_0_G_D_0_P_0" fill="freeze" attributeName="width" attributeType="XML" from="76" to="76" keyTimes="0;0.4918033;0.9836066;1" values="76;76;76;76" keySplines="0.167 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animate repeatCount="0" dur="2.0333333s" xlinkHref="#Brand_R_G_L_0_G_D_0_P_0" fill="freeze" attributeName="height" attributeType="XML" from="76" to="76" keyTimes="0;0.4918033;0.9836066;1" values="76;257;76;76" keySplines="0.167 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateTransform repeatCount="0" dur="2.0333333s" xlinkHref="#Brand_R_G_L_0_G_D_0_P_0" fill="freeze" attributeName="transform" from="-38 -38" to="-38 -38" type="translate" additive="sum" keyTimes="0;0.4918033;0.9836066;1" values="-38 -38;-38 -128.5;-38 -38;-38 -38" keySplines="0.167 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateMotion repeatCount="0" dur="2.0333333s" xlinkHref="#Brand_R_G_L_0_G" fill="freeze" keyTimes="0;0.4918033;0.9836066;1" path="M300 485.5 C300,485.5 300,394.5 300,394.5 C300,394.5 300,485.5 300,485.5 C300,485.5 300,485.5 300,485.5 " keyPoints="0;0.5;1;1" keySplines="0.167 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
                <animateTransform repeatCount="0" dur="2.0333333s" xlinkHref="#Brand_R_G_L_0_G" fill="freeze" attributeName="transform" from="61 -142" to="61 -142" type="translate" additive="sum" keyTimes="0;1" values="61 -142;61 -142" keySplines="0 0 1 1" calcMode="spline" />
            </defs>
            <g id="Brand_R_G">
                <g id="Brand_R_G_L_2_G" transform=" translate(250, 250) scale(1.72, 1.72) translate(65, -64)">
                <path id="Brand_R_G_L_2_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="12" strokeOpacity="1" d=" M51 15 C51,15 51,113 51,113 C51,149.98 20.98,180 -16,180 C-16,180 -114,180 -114,180 C-150.98,180 -181,149.98 -181,113 C-181,113 -181,15 -181,15 C-181,-21.98 -150.98,-52 -114,-52 C-114,-52 -16,-52 -16,-52 C20.98,-52 51,-21.98 51,15z " />
                </g>
                <g id="Brand_R_G_L_1_G">
                <g id="Brand_R_G_L_1_G_D_0_P_0_G_0_T_0" transform=" translate(-61, 13.5) translate(0, 0)">
                    <rect id="Brand_R_G_L_1_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="15" strokeOpacity="1" x="0" y="0" />
                </g>
                </g>
                <g id="Brand_R_G_L_0_G">
                    <g id="Brand_R_G_L_0_G_D_0_P_0_G_0_T_0" transform=" translate(-61, 13.5) translate(0, 0)">
                        <rect id="Brand_R_G_L_0_G_D_0_P_0" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="15" strokeOpacity="1" x="0" y="0" rx="141" ry="141" />
                    </g>
                </g>
            </g>
            <g id="time_group" />
        </svg>
    )
}